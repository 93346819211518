.carousel-caption {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff !important;
    background: #460E7B ;
    text-align: center;
}

.custom-tag {
    max-width: 100%;
    height: 700px;
}

.carousel-caption>h3 {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 51px;
    color: white;
}

.login-container {
    padding-top: 30px;
}

.modal-content {
    top: 200px;
}

.invalid-feedback {
    /* display: block; */
  }
